<template>
    <div v-if="isLoading" class="row justify-content-md-center">
        <div class="col-3 alert-info text-center-mt-5">
            Espere por favor ...
            <h3 class="mt-2">
                <i class="fa fa-spin fa-sync"></i>
            </h3>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            isLoading: true,
        };
    },

    computed: {
        ...mapGetters('users', ['isUserLoggedIn'])
    },

    methods: {
        ...mapActions('users', ['logout']),

        async performLogout() {
            await this.logout()
            if (this.isUserLoggedIn) {
                console.error("Error logging out user")
            } else {
                console.log("User logged out");
                this.$router.go()
            }
        }
    },

    created() {
        this.performLogout()
    }
};
</script>

<style>
</style>